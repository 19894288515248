import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL, IS_USDT_AVAILABLE, getURLParam } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import general from "../css/General.module.css";

function AllBankCards() {
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Bank account",
    bankCardMethod: "",
    isLoadingShow: false,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn2") {
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const setCardPrimary = (id) => {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-set-bankcard-primary",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          getGameRecords();
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (id != "") {
      updateLoadingStatus(true);
      fecthApiData(
        API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&CARD_ID=" + id
      );
    }
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pr-v w-100 br-5 mg-t-15 bg-l-blue"
          onClick={() => setCardPrimary(data[i]["c_bank_id"])}
        >
          <div
            className={`pd-5-15 br-5 ft-sz-16 ft-wgt-500 cl-white ${data[i]["c_is_primary"] == "true" ? "bg-blue" : "bg-grey-2"
              }`}
          >
            {data[i]["c_is_primary"] == "true" ? "Primary" : "Set Primary"}
          </div>
          <div
            className={`ps-ab ps-tp ps-rgt pd-5-15 br-5 ft-sz-16 cl-white bg-yellow`}
          >
            Verified
          </div>

          <div className="col-view w-100 mg-t-5 pd-5-15">
            <span className="ft-sz-15 mg-t-5">
              Name: {data[i]["c_beneficiary"]}
            </span>
            <span className="ft-sz-15 mg-t-5">
              ACC: {data[i]["c_bank_account"]}
            </span>
            <span className="ft-sz-15 mg-t-5">
              {data[i]["c_bank_ifsc_code"] == "none"
                ? "Method: USDT"
                : "IFSC: " + data[i]["c_bank_ifsc_code"]}
            </span>
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getGameRecords() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-get-bankcards",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();
        console.log(data);
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  useEffect(() => {
    if (getURLParam("M") != null && getURLParam("M") != "") {
      setConstants((previousState) => {
        return { ...previousState, bankCardMethod: getURLParam("M") };
      });
    }

    getGameRecords();
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">
              {pageConst.recordList}

              <Link
                className={[
                  general.width100,
                  general.textDecoNone,
                  general.colView,
                  general.alignCenter,
                  general.pd15px10px,
                  general.mgT10px,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                to={"/addbankcard"}
              >
                <img
                  className={[general.height50px]}
                  src={require("../media/icons/add_icon.png")}
                  alt="icon"
                />

                <span
                  className={[
                    general.colorGrey,
                    general.fontS16Px,
                    general.mgT10px,
                  ].join(" ")}
                >
                  {IS_USDT_AVAILABLE ? "Add a Bank Account and USDT Address" : "Add a Bank Account"}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllBankCards;