import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import LoadingDialog from '../dialogs/LoadingDialog/dialog';
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { openNewPage, copyText, AVATARS_ARR, API_ACCESS_URL } from "../modals/Constants";
import { getStorage, setStorage, deleteStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Account() {
  const toastDialogRef = useRef(null);
  const [accountBalance, setAccountBalance] = useState(Number(getStorage("balance")));
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };
  
  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    showToast("ID Copied!", 2500, 0, 1);
  };

  function refreshBalance() {
    async function requestAPI(url, formData) {
        try {
          const res = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              route: "route-account-balance",
              AuthToken: getStorage("secret"),
            },
            body: JSON.stringify(formData),
          });
      
          const data = await res.json();
          updateLoadingStatus(false);
      
          if (data.status_code == "false") {
            showToast("There is a technical issue!");
          } else{
            setAccountBalance(data.balance);
            setStorage("balance", data.balance);
          }
        } catch (error) {
          updateLoadingStatus(false);
          showToast(
            "There was a technical issue! Please try again!"
          );
        }
    }
      
    const formData = {
      ACCOUNT_ID: getStorage("uid"),
    };
    updateLoadingStatus(true);
    requestAPI(API_ACCESS_URL, formData);
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <LoadingDialog intentData={showLoading} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <div className="zoomAnimView">
          <div
            className={[
              general.rowView,
              general.alignFlexStart,
              styles.accountView,
              general.bgCustomColor1,
            ].join(" ")}
          >
            <div
              className="h-w-70 ovf-hidden br-50"
              onClick={() => navigate("/updateaccount", { replace: false })}
            >
              {getStorage("avatarId") ? (
                <img
                  src={
                    AVATARS_ARR[Number(getStorage("avatarId")) - 1]["avatarURL"]
                  }
                  className="h-w-70"
                  alt="icon"
                />
              ) : (
                ""
              )}
            </div>

            <div className="col-view mg-l-15">
              <Link className="row-view txt-deco-n" to={"/changename"}>
                <div className="cl-white ft-wgt-500 ft-sz-1p25rem">
                  {getStorage("uname")}
                </div>
                {getStorage("accountLevel") ?
                  <img
                    src={require(`../media/icons/vip_${getStorage("accountLevel")}_bedge.png`)}
                    className="w-65-p mg-l-10"
                    alt="icon"
                  />
                  : null}

              </Link>

              <div
                className="cl-white ft-sz-14 mg-t-5"
                onClick={() => copyMyId()}
              >
                UID &nbsp; | &nbsp; {getStorage("uid")}
                <img
                  src={require("../icons/copy_icon.png")}
                  className="h-10-p mg-l-15"
                  alt="icon"
                />
              </div>
              <div className="cl-white ft-sz-14 mg-t-5">
                Last Active: {getStorage("accountLastActive")}
              </div>
            </div>
          </div>

          <div
            className={[styles.accountOptionsView, styles.colView].join(" ")}
          >
            <div className="w-100 z-i-1">
              <div
                className={[styles.colView, styles.accountBalanceView].join(
                  " "
                )}
              >
                <span>Account Balance:</span>
                <div className={[
                    general.rowView
                  ].join(" ")}>
                  <span className={[general.fontS23Px]}>
                    ₹{accountBalance.toLocaleString()}
                  </span>
                  <img
                    src={require("../icons/refresh_icon.png")}
                    className="h-18-p mg-l-15"
                    alt="icon"
                    onClick={() => refreshBalance()}
                  />
                </div>

                <div className="line-hv-grey mg-t-10"></div>

                <div
                  className={[
                    general.rowView,
                    general.spaceBetween,
                    general.pd10px15px,
                  ].join(" ")}
                >
                  <Link
                    className={[
                      general.textDecoNone,
                      general.colView,
                      general.viewCenter,
                    ].join(" ")}
                    to={"/recharge"}
                  >
                    <img
                      src={require("../media/icons/deposit_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span
                      className={[general.colorGrey, general.fontS15Px].join(
                        " "
                      )}
                    >
                      Deposit
                    </span>
                  </Link>

                  <Link
                    className={[
                      general.textDecoNone,
                      general.colView,
                      general.viewCenter,
                    ].join(" ")}
                    to={"/withdraw"}
                  >
                    <img
                      src={require("../media/icons/withdrawl_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span
                      className={[general.colorGrey, general.fontS15Px].join(
                        " "
                      )}
                    >
                      Withdraw
                    </span>
                  </Link>

                  <div
                    className={[general.colView, general.viewCenter].join(" ")}
                    onClick={() => openNewPage(getStorage("appDownloadURL"))}
                  >
                    <img
                      src={require("../media/icons/download_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span
                      className={[general.colorGrey, general.fontS15Px].join(
                        " "
                      )}
                    >
                      Download
                    </span>
                  </div>

                  <Link
                    className={[
                      general.textDecoNone,
                      general.colView,
                      general.viewCenter,
                    ].join(" ")}
                    to={"/vip"}
                  >
                    <img
                      src={require("../media/icons/vip_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span
                      className={[general.colorGrey, general.fontS15Px].join(
                        " "
                      )}
                    >
                      Vip
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className={[styles.accountShortCutOptionsView]}>
              <Link className={[general.rowView]} to={"/rechargerecords"}>
                <img
                  src={require("../media/icons/deposit_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span
                    className={[general.fontS17Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Deposit
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(" ")}
                  >
                    My Deposit History
                  </span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/withdrawrecords"}
              >
                <img
                  src={require("../media/icons/withdrawl_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span
                    className={[general.fontS17Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Withdraw
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(" ")}
                  >
                    My Withdraw History
                  </span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/myorders"}
              >
                <img
                  src={require("../media/icons/bet_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span
                    className={[general.fontS17Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Bet
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(" ")}
                  >
                    My Bet History
                  </span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/transactions"}
              >
                <img
                  src={require("../media/icons/transactions_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span
                    className={[general.fontS17Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Transactions
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(" ")}
                  >
                    My Transactions History
                  </span>
                </div>
              </Link>
            </div>

            <div
              className={[styles.accountMoreOptionsView, general.mgB100px].join(
                " "
              )}
            >
              <Link
                className={[general.rowView, general.spaceBetween, general.hideView].join(" ")}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/notification_icon.png")}
                    alt="icon"
                  />
                  <span>Notification</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[general.rowView, general.spaceBetween].join(" ")}
                onClick={() => openNewPage(getStorage("liveChatURL"))}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/live_support_icon.png")}
                    alt="icon"
                  />
                  <span>Live Support</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[
                  general.rowView,
                  general.spaceBetween,
                  Number(getStorage("accountLevel")) <= 2
                    ? general.hideView
                    : "",
                ].join(" ")}
                to={"/agentreports"}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/transaction_icon.png")}
                    alt="icon"
                  />
                  <span>Agent Report</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[general.rowView, general.spaceBetween].join(" ")}
                onClick={() => openNewPage(getStorage("appDownloadURL"))}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/download_icon.png")}
                    alt="icon"
                  />
                  <span>Download</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[general.rowView, general.spaceBetween].join(" ")}
                to={"/redeemgiftcard"}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/gift_icon.png")}
                    alt="icon"
                  />
                  <span>Redeem Gift</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[general.rowView, general.spaceBetween, general.hideView].join(" ")}
                to={"/redeemgiftcard"}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/statistics_icon.png")}
                    alt="icon"
                  />
                  <span>Game Statistics</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[general.rowView, general.spaceBetween].join(" ")}
                to={"/devicemanager"}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/device_manager_icon.png")}
                    alt="icon"
                  />
                  <span>Device Manager</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[general.rowView, general.spaceBetween].join(" ")}
                onClick={() => openNewPage(getStorage("telegramURL"))}
              >
                <div className={[general.viewCenter]}>
                  <img
                    src={require("../media/icons/notification_icon.png")}
                    alt="icon"
                  />
                  <span>Telegram</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#000000"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <div className={[general.pd10px]}>
                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT30px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => signOutAccount()}
                >
                  <div className="v-center">
                    <span className="cl-white mg-l-10">Sign Out</span>
                  </div>

                  <img
                    className={[general.heightWidth15px, general.mgL10px].join(
                      " "
                    )}
                    src={require("../media/icons/angle_right_white_icon.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="account" />
      </div>
    </div>
  );
}

export default Account;
